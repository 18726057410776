<template>
    <v-app>
        <v-container>
            <v-row>
                <v-col sm="12" cols="12">
                    <v-card outlined dense class="pa-4 no_border rounded-sm" color="white">
                        <v-row>
                            <v-col class="bigSide py-0" sm="8" cols="12" style="transition: .3s ease-in;"
                                   :class="{'hide_big_bar_class':isHideBar}">
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-card outlined dense class="no_border">
                                        <h2 class="mb-0">{{ $t('delivery_order') }}</h2>
                                        <v-icon v-if="isHideBar"
                                                onclick="window.history.go(-1); return false;"
                                                style="cursor: pointer;  font-size: 40px;"
                                                color="grey"
                                                class="float-right"
                                        >close
                                        </v-icon>
                                        <span style="transition: .3s ease-in; z-index:10;"
                                              :class="{'iconArrow': !isHideBar,'iconArrowHide': isHideBar }">
                                            <v-icon size="22"
                                                    class="arr_icon"
                                                    @click="hide_smallsitebar"
                                                    v-if="isHideBar"> fa fa-chevron-circle-left
                                            </v-icon>
                                            <v-icon size="22"
                                                    class="arr_icon"
                                                    @click="hide_smallsitebar"
                                                    v-if="!isHideBar"> fa fa-chevron-circle-right
                                            </v-icon>
										</span>
                                    </v-card>

                                    <v-card outlined dense class="px-3 no_border mb-3" color="grayBg" height="170px">
                                        <v-row>
                                            <v-col sm="4" cols="12" class="pb-0">
                                                <label class="label">{{ $t('customer') }}</label>
                                                <v-col sm="12" cols="12"
                                                       class="kendo_dropdown_custom pl-0 pb-3 pt-0">
                                                    <dropdownlist
                                                        :data-items="customerList"
                                                        @change="onChange"
                                                        :value="deliveryOrder.customer"
                                                        :data-item-key="dataItemKey"
                                                        :text-field="textField"
                                                        :default-item="defaultItem"
                                                        :filterable="true"
                                                        @filterchange="onFilterChange">
                                                    </dropdownlist>
                                                </v-col>
                                                <label class="label">{{ $t('number') }}</label>
                                                <v-row class="mt-1 mr-0">
                                                    <v-col sm="3" cols="3" class="pt-0 pr-0">
                                                        <div class="code_text text-bold">{{ deliveryOrder.abbr }}</div>
                                                    </v-col>
                                                    <v-col sm="7" cols="7" class="pt-0 pl-0 pr-1">
                                                        <v-text-field class=" custom-border "
                                                                      v-model="deliveryOrder.number"
                                                                      outlined
                                                                      disabled
                                                                      :rules="[v => !!v || 'Number is required']"
                                                                      required/>
                                                    </v-col>
                                                    <v-col sm="2" cols="2" class="pt-0 px-0">
                                                        <v-icon color="black" style="background: #fff;" size="30"
                                                                class="border_qrcode"
                                                                @click="generateNumber">mdi-qrcode
                                                        </v-icon>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col sm="4" cols="12" class="pb-0">
                                                <label class="label">{{ $t('date') }}</label>
                                                <app-datepicker :initialDate="deliveryOrder.date"
                                                                @emitDate="deliveryOrder.date = $event"/>
                                                <label class="label">{{ $t('warehouse') }}</label>
                                                <v-select class="mt-1"
                                                          :items="warehouses"
                                                          item-value="id"
                                                          item-text="name"
                                                          v-model="deliveryOrder.warehouse"
                                                          tage="Warehouse"
                                                          :rules="[v => !!v || 'Warehouse is required']"
                                                          return-object
                                                          outlined
                                                />
                                            </v-col>
                                            <v-col sm="4" cols="12" class="pb-0">
                                                <label class="label">{{ $t('invoice_sale_order') }}</label>
                                                <v-col sm="12" cols="12"
                                                       class="kendo_dropdown_custom pl-0 pb-3 pt-0">
                                                    <kendo-datasource ref="datasourceIn"
                                                                      :data="invoices"
                                                                      :group-field="'type'">
                                                    </kendo-datasource>
                                                    <k-dropdown
                                                        :filter="'startswith'"
                                                        @change="onChangeInvoice"
                                                        class="mt-1"
                                                        :value="deliveryOrder.invoice"
                                                        :data-source-ref="'datasourceIn'"
                                                        :data-text-field="'referenceNo'"
                                                        :data-value-field="dataItemKey"
                                                        :option-label="'Select Invoice...'"
                                                        :disabled="invoices.length === 0">
                                                    </k-dropdown>
                                                </v-col>
                                                <label class="label">{{ $t('delivery_address') }}</label>
                                                <v-select class="mt-1"
                                                          :items="deliveryAddress"
                                                          item-text="name"
                                                          item-value="id"
                                                          v-model="deliveryOrder.address"
                                                          tage="Delivery Address"
                                                          outlined
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-card>

                                    <v-row color="white">
                                        <v-col sm="12" cols="12" class="pt-0">
                                            <kendo-datasource ref="dataSource"
                                                              :change="dataSourceChange"
                                                              :data="deliveryOrder.itemLines"
                                            />
                                            <kendo-grid id="grid" class="grid-function"
                                                        :data-source-ref="'dataSource'"
                                                        :sortable="false"
                                                        :filterable="false"
                                                        :column-menu="true"
                                                        :editable="true"
                                                        :scrollable-virtual="true"
                                            >
                                                <kendo-grid-column
                                                    :template="rowNumber"
                                                    :title="$t('no.')"
                                                    :width="55"
                                                    :column-menu="false"
                                                    :headerAttributes="{
                                                                    style: 'background-color: #EDF1F5;',
                                                                    class: 'text-variants'
                                                                }"
                                                    :attributes="{
                                                                    style: 'text-align: variants'
                                                                }"/>
                                                <kendo-grid-column
                                                    :field="'item'"
                                                    :title="$t('items')"
                                                    :width="200"
                                                    :template="'<span>#=item.name#</span>'"
                                                    :editor="ItemDropDownEditor"
                                                    :headerAttributes="{style: 'background-color: #EDF1F5'}"/>
                                                <kendo-grid-column
                                                    :field="'balance'"
                                                    :editor="numberEditor"
                                                    :title="$t('stock')"
                                                    :format="'{0:n}'"
                                                    :attributes="{class: 'k-state-disabled'}"
                                                    :width="120"
                                                    :headerAttributes="{style: 'background-color: #EDF1F5'}"/>
                                                <kendo-grid-column
                                                    :field="'orderQty'"
                                                    :editor="numberEditor"
                                                    :title="$t('order_qty')"
                                                    :format="'{0:n}'"
                                                    :attributes="{class: 'k-state-disabled'}"
                                                    :width="150"
                                                    :headerAttributes="{style: 'background-color: #EDF1F5'}"/>
                                                <kendo-grid-column
                                                    :field="'qty'"
                                                    :editor="numberEditor"
                                                    :title="$t('qty')"
                                                    :format="'{0:n}'"
                                                    :attributes="{class: 'k-state-disabled isQty'}"
                                                    :width="100"
                                                    :headerAttributes="{style: 'background-color: #EDF1F5'}"/>
                                                <kendo-grid-column
                                                    :field="'uom'"
                                                    :template="UOMTemplate"
                                                    :editor="UOMDropDownEditor"
                                                    :title="$t('uom')"
                                                    :width="130"
                                                    :headerAttributes="{style: 'background-color: #EDF1F5'}"/>
                                                <kendo-grid-column
                                                    :command="{ iconClass: 'k-icon k-i-trash', text: 'Delete', click: removeRow, class: 'btn-plus' }"
                                                    :title="'&nbsp;'"
                                                    :width="100"
                                                    :headerAttributes="{style: 'text-align: right; background-color: #EDF1F5'}"
                                                />
                                            </kendo-grid>

                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn color="primary"
                                                   class="float-left btn_plus rounded-0 white--text mr-2"
                                                   @click="addRow">
                                                <v-icon size="" class="ma-1">fa-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col sm="12" cols="12" class="pb-0">
                                            <v-row>
                                                <v-col sm="5" cols="12" class="py-0">
                                                    <template>
                                                        <v-file-input class="mt-0 pt-0 " sm="3" cols="6"
                                                                      v-model="files"
                                                                      :placeholder="$t('attachment')"
                                                                      multiple
                                                                      prepend-icon="mdi-paperclip"
                                                        >
                                                            <template v-slot:selection="{ text }">
                                                                <v-chip
                                                                    small
                                                                    label
                                                                    color="primary"
                                                                >
                                                                    {{ text }}
                                                                </v-chip>
                                                            </template>
                                                        </v-file-input>
                                                    </template>
                                                </v-col>
                                                <v-col sm="7" cols="12" class="py-0">
                                                    <v-row>
                                                        <v-col sm="6" cols="12" class="pb-0">
                                                            <label class="label  mb-0">{{ $t('delivered_by') }}</label>
                                                            <v-select class="mt-1"
                                                                      :items="deliveryAgencies"
                                                                      :item-text="item => item.cusSup ? `${item.aType === 1 ? 'Internal' : 'External' } - ${item.cusSup.name}` : ''"
                                                                      item-value="id"
                                                                      :rules="[v => !!v || 'Delivered by is required']"
                                                                      v-model="deliveryOrder.deliveredBy"
                                                                      tage="Delivered by"
                                                                      outlined
                                                            />
                                                            <label
                                                                class="label  mb-0">{{ $t('vehicle_number') }}</label>
                                                            <v-text-field class="mt-1"
                                                                          v-model="deliveryOrder.vehicleNumber"
                                                                          outlined
                                                                          tage="Vehicle Number"
                                                                          required/>
                                                        </v-col>
                                                        <v-col sm="6" cols="12" class="pb-0">
                                                            <label class="label mb-0">{{ $t('time') }}</label>
                                                            <v-menu
                                                                v-model="menu2"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                ref="menu"
                                                                transition="scale-transition"
                                                                :return-value.sync="deliveryOrder.timeOfDischarged"
                                                                max-width="340">
                                                                <template
                                                                    v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        class="mt-1"
                                                                        v-model="deliveryOrder.timeOfDischarged"
                                                                        readonly
                                                                        outlined
                                                                        append-icon="mdi-clock-time-four-outline"
                                                                        v-on="on"
                                                                        v-bind="attrs"
                                                                        @click:clear="deliveryOrder.timeOfDischarged = null"
                                                                        @click:append="menu2 = true"
                                                                        required/>
                                                                </template>
                                                                <v-time-picker
                                                                    v-if="menu2"
                                                                    v-model="deliveryOrder.timeOfDischarged"
                                                                    full-width
                                                                    no-title
                                                                    @click:minute="$refs.menu.save(deliveryOrder.timeOfDischarged)"
                                                                ></v-time-picker>
                                                            </v-menu>
                                                            <label class="label  mb-0">{{ $t('references') }}</label>
                                                            <v-text-field class="mt-1"
                                                                          tage="Driver References"
                                                                          v-model="deliveryOrder.driverReferences"
                                                                          return-object
                                                                          outlined
                                                            />

                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-divider/>
                                    <v-card outlined dense class="no_border function_footer">
                                        <v-menu>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="black"
                                                    outlined
                                                    class="mr-2 text-capitalize rounded-pill black--text float-left"
                                                    v-on="on"
                                                >
                                                    {{ $t("select_template") }}
                                                    <v-icon size="" class="ma-1">expand_more</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    v-for="(item, index) in templates"
                                                    :key="index"
                                                >
                                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-btn
                                            color="black"
                                            class="text-capitalize rounded-pill white--text float-left"
                                        >{{ $t("cancel") }}
                                        </v-btn
                                        >
                                        <v-menu>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="primary"
                                                    class="ml-2 float-right text-capitalize rounded-pill white--text"
                                                    v-on="on"
                                                >
                                                    {{ $t("save_option") }}
                                                    <v-icon size="" class="ma-1">expand_more</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list rounded>
                                                <v-list-item-group>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ $t("save_new") }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                            >{{ $t("save_print") }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-menu>
                                        <v-btn
                                            @click="saveNew"
                                            color="secondary"
                                            class="float-right white--text text-capitalize rounded-pill"
                                        >
                                            {{ $t("save_draft") }}
                                        </v-btn>
                                    </v-card>
                                </v-form>
                            </v-col>
                            <v-col class="smallSide" sm="4" style="transition: .3s ease-in;"
                                   :class="{'hide_small_bar_class':isHideBar}">
                                <h3 style="color: #b3b5bc; font-size:20px;" v-if="!isHideBar"
                                    class="text-uppercase float-right mt-n3">
                                    <span class="pointer" @click="Help('delivery order')">
                                        {{ $t('help') }}
                                    </span>
                                    <v-icon
                                        onclick="window.history.go(-1); return false;"
                                        style="cursor: pointer; color: #333; font-size: 40px;"
                                        class="float-right mt-n1"
                                    >close
                                    </v-icon>
                                </h3>

                                <v-card outlined dense v-if="!isHideBar" class="small_sidebar mt-9 no_border"
                                        color="grayBg">
                                    <!--                                    <v-row>-->
                                    <!--                                        <v-col sm="12" cols="12">-->
                                    <!--                                            <h4 class="mb-2 text-uppercase">{{ $t('analysis') }}</h4>-->
                                    <!--                                            <v-row>-->
                                    <!--                                                <v-col sm="12" cols="12" class="pt-1">-->
                                    <!--                                                    &lt;!&ndash;                                                    <label class="label mb-0">{{ $t('segment') }}</label>&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                    <v-select class="mt-2"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              v-model="deliveryOrder.segment"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              :items="segments"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              item-value="id"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              :item-text="item =>`${item.code} - ${item.name}`"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              return-object&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              tage="sub Of"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              placeholder="Sub Of"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              outlined=""/>&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                    <label class="label mb-0">{{ $t('location') }}</label>&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                    <v-select class="mt-2"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              v-model="deliveryOrder.location"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              :items="locations"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              item-value="id"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              :item-text="item =>`${item.code} - ${item.name}`"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              return-object&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              tage="Location"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              placeholder="bu/location"&ndash;&gt;-->
                                    <!--                                                    &lt;!&ndash;                                                              outlined=""/>&ndash;&gt;-->
                                    <!--                                                    <label class="label mb-0 font_14">{{ $t('sale_channel') }}</label>-->
                                    <!--                                                    <v-select class=" my-2"-->
                                    <!--                                                              v-model="deliveryOrder.saleChannel"-->
                                    <!--                                                              :items="saleChannelList"-->
                                    <!--                                                              item-value="id"-->
                                    <!--                                                              item-text="name"-->
                                    <!--                                                              return-object-->
                                    <!--                                                              tage="Sale Channel"-->
                                    <!--                                                              placeholder="Channel"-->
                                    <!--                                                              outlined-->
                                    <!--                                                    />-->
                                    <!--                                                    <label class="label">{{ $t("project") }}</label>-->
                                    <!--                                                    <v-select-->
                                    <!--                                                        class="mt-1"-->
                                    <!--                                                        v-model="deliveryOrder.project"-->
                                    <!--                                                        :items="projects"-->
                                    <!--                                                        :item-text="item =>`${item.code} - ${item.name}`"-->
                                    <!--                                                        item-value="id"-->
                                    <!--                                                        tage="Project"-->
                                    <!--                                                        outlined-->
                                    <!--                                                    />-->
                                    <!--                                                    <label class="label">{{ $t("for_month_of") }}</label>-->
                                    <!--                                                    <app-monthpicker-->
                                    <!--                                                        :initMonthOf="deliveryOrder.monthOf"-->
                                    <!--                                                        @emitMonthOf="deliveryOrder.monthOf = $event"-->
                                    <!--                                                    />-->

                                    <!--                                                    <v-row v-for="(invoice, index) in invoices" v-bind:key="invoice.id">-->
                                    <!--                                                        <label class="text-bold ml-9"-->
                                    <!--                                                               style="font-size: 12px; width: 100%;">{{ index + 1 }}.-->
                                    <!--                                                            {{ $t('invoice') }}</label>-->
                                    <!--                                                        <v-col sm="9" cols="9" class="py-0 pr-0">-->
                                    <!--                                                            <v-card outlined-->
                                    <!--                                                                    class="px-3 py-1 text-white no-boxshadow no_border justify-left d-flex"-->
                                    <!--                                                                    style="background-color: #92d050; height: 30px">-->
                                    <!--                                                                &lt;!&ndash;                                                                <input type="checkbox" class="checkbox_inv float-left"/>&ndash;&gt;-->
                                    <!--                                                                <p class="mb-0" style="width: 100%">-->
                                    <!--                                                                    <span class="pl-3 float-left">{{-->
                                    <!--                                                                            invoice.referenceNo-->
                                    <!--                                                                        }}</span>-->
                                    <!--                                                                    <span class="float-right">{{-->
                                    <!--                                                                            invoice.amount.toLocaleString()-->
                                    <!--                                                                        }}</span>-->
                                    <!--                                                                </p>-->
                                    <!--                                                            </v-card>-->
                                    <!--                                                        </v-col>-->
                                    <!--                                                        <v-col sm="3" cols="3" class="py-0  pl-1">-->
                                    <!--                                                            <v-btn-->
                                    <!--                                                                v-if="invoice.isAdd !== 1"-->
                                    <!--                                                                @click="addItemLine(invoice)"-->
                                    <!--                                                                class="text-white text-bold rounded-0 float-right text-uppercase"-->
                                    <!--                                                                style="height: 30px !important; background-color: rgb(0, 176, 80) !important">-->
                                    <!--                                                                {{ $t('add') }}-->
                                    <!--                                                            </v-btn>-->
                                    <!--                                                            <v-btn-->
                                    <!--                                                                v-else-->
                                    <!--                                                                disabled-->
                                    <!--                                                                class="text-white text-bold pa-0 rounded-0 float-right text-uppercase"-->
                                    <!--                                                                style="height: 30px !important; background-color: rgb(0, 176, 80) !important">-->
                                    <!--                                                                {{ $t('added') }}-->
                                    <!--                                                            </v-btn>-->
                                    <!--                                                        </v-col>-->
                                    <!--                                                    </v-row>-->
                                    <!--                                                    <v-row v-for="(so, index) in saleOrders" v-bind:key="so.id">-->
                                    <!--                                                        <label class="text-bold ml-9 mt-2" style="font-size: 12px;">{{ index + 1 + invoices.length }}.-->
                                    <!--                                                            {{ $t('sale_order') }}</label>-->
                                    <!--                                                        <v-col sm="9" cols="9" class="py-0 pr-0">-->
                                    <!--                                                            <v-card outlined-->
                                    <!--                                                                    class="px-3 py-1 text-white no-boxshadow no_border justify-left d-flex"-->
                                    <!--                                                                    style="background-color: #92d050; height: 30px">-->
                                    <!--                                                                <input type="checkbox" class="checkbox_inv float-left"/>-->
                                    <!--                                                                <p class="mb-0" style="width: 100%">-->
                                    <!--                                                                    <span class="pl-3 float-left">{{ so.referenceNo }}</span>-->
                                    <!--                                                                    <span class="float-right">{{ so.amount.toLocaleString() }}</span>-->
                                    <!--                                                                </p>-->
                                    <!--                                                            </v-card>-->
                                    <!--                                                        </v-col>-->
                                    <!--                                                        <v-col sm="3" cols="3" class="py-0  pl-1">-->
                                    <!--                                                            <v-btn-->
                                    <!--                                                                class="text-white text-bold rounded-0 float-right text-uppercase"-->
                                    <!--                                                                style="height: 30px !important; background-color: rgb(0, 176, 80) !important">-->
                                    <!--                                                                {{ $t('add') }}-->
                                    <!--                                                            </v-btn>-->
                                    <!--                                                        </v-col>-->
                                    <!--                                                    </v-row>-->

                                    <!--                                                </v-col>-->
                                    <!--                                            </v-row>-->
                                    <!--                                        </v-col>-->
                                    <!--                                    </v-row>-->

                                    <p class="mb-0 mt-3 detial_smallside_p font_14" style="margin-top: 20%;">
                                        {{ $t('delivery_order_desc') }}</p>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-col>
            </v-row>
            <LoadingMe
                :isLoading="showLoading"
                :fullPage="true"
                :myLoading="true"
                :alertMessage="loadingAlert"
                :color="loadingColorAlert"
                :alertText="loadingTextAlert"/>
        </v-container>
    </v-app>
</template>

<script>
// import kendo from '@progress/kendo-ui'
import LoadingMe from '@/components/Loading'
import DatePickerComponent from '@/components/custom_templates/DatePickerComponent'
import DeliveryOrder from "@/scripts/model/DeliveryOrder";
import {DropDownList as DropDownList2} from "@progress/kendo-vue-dropdowns";
import {DropDownList as DropDownList1} from '@progress/kendo-dropdowns-vue-wrapper';
import {KendoDataSource} from '@progress/kendo-datasource-vue-wrapper';
// import MonthOfPicker from "@/components/kendo_templates/MonthOfPicker";
import kendo from "@progress/kendo-ui";
import {ShowResource} from '@/observable/store'
import {uuid} from "vue-uuid";

const $ = require("jquery")
const deliveryOrderHandler = require("@/scripts/deliveryOrderHandler")
const customerHandler = require("@/scripts/customerHandler")
const productVariantHandler = require("@/scripts/productVariantHandler")
const prefixHandler = require("@/scripts/prefixHandler")
const billingHandler = require("@/scripts/invoice/handler/billingHandler")
const settingsHandler = require("@/scripts/settingsHandler")
const saleChannelHandler = require("@/scripts/saleChannelHandler")
const locationHandler = require("@/scripts/locationHandler")
const projectHandler = require("@/scripts/projectHandler")
const warehouseHandler = require("@/scripts/warehouseHandler")
const uomPriceHandler = require("@/scripts/uomPriceHandler")
const saleOrderHandler = require("@/scripts/transactionHandler")
const deliveryOrder = new DeliveryOrder({})
const keyField = 'id'
const textField = 'name'
const emptyItem = {[textField]: 'loading ...'}
const itemLinePrefix = 'lin-'
export default {
    name: "CreditPurchase",
    components: {
        'LoadingMe': LoadingMe,
        'k-dropdown': DropDownList1,
        'app-datepicker': DatePickerComponent,
        'kendo-datasource': KendoDataSource,
        'dropdownlist': DropDownList2,
    },
    data: () => ({
        menu2: false,
        isEdit: false,
        // LoadingMe
        showLoading: false,
        loadingAlert: false,
        loadingColorAlert: '',
        loadingTextAlert: '',
        bill_date: '',
        alert: false,
        deposite: 1000,
        files: [],
        // Form validation
        valid: true,

        journal_types: [],
        suppliers_item: [],
        expense: [
            'Equal',
            'weighted'
        ],
        journal_date: "",
        templates: [
            {title: 'Draft'},
            {title: 'Open'},
            {title: 'Partially Closed'},
            {title: 'Closed'},
        ],
        col_expand: 9,
        col_hide: 3,
        isHideBar: false,
        suppliers: ["Suppliers", "Customer", "Employee"],
        transaction: [
            {
                id: "12",
                name: "Purchase Order",
                no: "No.0024",
                price: "2,50000",
            },
            {
                id: "14",
                name: "Godd Recipt Note",
                no: "No.0024",
                price: "2,50000",
            }
        ],
        item_suppliers: [
            {
                name: "Subtotal",
                value: "10000.00"
            },
            {
                name: "Subtotal",
                value: "10000.00"
            },
            {
                name: "Subtotal",
                value: "10000.00"
            },
            {
                name: "Subtotal",
                value: "10000.00"
            },
            {
                name: "Subtotal",
                value: "10000.00"
            },
        ],

        batchs: [],
        warehouses: [],
        saleChannelList: [],
        projects: [],
        locations: [],
        segments: [],
        itemLines: [],
        customerList: [],
        dataItemKey: 'id',
        textField: 'name',
        defaultItem: {[textField]: 'Select supplier...', [keyField]: null},
        customerBaseUrl: customerHandler.search(),
        filter: '',
        invoices: [],
        saleOrders: [],
        deliveryOrder: deliveryOrder,
        deliveryAgencies: [],
        balanceWarehouse: {},
        deliveryAddress: []
    }),
    methods: {
        Help(key) {
            ShowResource(key)
        },
        numberEditor(container, options) {
            kendo.jQuery('<input data-bind="value:' + options.field + '"/>')
                .appendTo(container)
                .kendoNumericTextBox({
                    decimals: 30,
                    min: 0,
                    max: this.balanceWarehouse.qty
                });
        },
        async onloadDeliveryAgency() {
            await deliveryOrderHandler.getAllAgency().then(res => {
                this.deliveryAgencies = res
            })
        },
        async addItemLine(data) {
            window.console.log(data, 'data234')
            this.deliveryOrder.itemLines = []
            data.isAdd = 1
            for (const i of data.itemLines) {
                window.console.log(i.item, 'itemlines')
                await this.getBalanceWarehouse(i.item.id)
                let balance = 0, qty = 0
                if (Object.keys(this.balanceWarehouse).length !== 0) {
                    if (this.balanceWarehouse.bQty > 0) {
                        balance = this.balanceWarehouse.bQty
                        if (this.balanceWarehouse.bQty < i.qty) {
                            qty = this.balanceWarehouse.bQty
                        }
                    } else {
                        this.$snotify.warning('Please check stock of this item : ' + i.item.name)
                        continue
                    }
                } else {
                    this.$snotify.warning('Please check stock of this item : ' + i.item.name)
                    continue
                }
                this.deliveryOrder.itemLines.push({
                    id: i.id,
                    item: i.item,
                    balance: balance,
                    description: i.description,
                    orderQty: i.qty,
                    qty: qty,
                    uom: i.uom,
                    invoiceId: data.id,
                    type: data.type,
                })
            }
        },
        async getBalanceWarehouse(id) {
            window.console.log(this.deliveryOrder.date, this.deliveryOrder.warehouse.id, 'balance warehouse')
            const data = {
                itmId: id,
                date: this.deliveryOrder.date ? this.deliveryOrder.date : new Date(),
                whId: this.deliveryOrder.warehouse ? this.deliveryOrder.warehouse.id : ''
            }
            await warehouseHandler.getBalance(data).then(res => {
                if (res.length > 0) {
                    this.balanceWarehouse = res[0]
                }
                window.console.log(this.balanceWarehouse, 'balance warehouse')
            })
        },
        dataSourceChange(e) {
            if (e.field) {
                let dataRow = e.items[0]
                switch (e.field) {
                    case "item":
                        this.getBalanceWarehouse(dataRow.item.id).then(() => {
                            dataRow.set('balance', this.balanceWarehouse ? this.balanceWarehouse.bQty : 0)
                        })
                        if (this.balanceWarehouse.bQty > 0) {
                            kendo.jQuery("tr[data-uid='" + dataRow.uid + "']").find(".isQty").each(function () {
                                kendo.jQuery(this).removeClass('k-state-disabled')
                            })
                        } else {
                            dataRow.set('balance', 0)
                        }
                        break
                    default:
                        break
                }
            }
        },
        addRow() {
            let data = {
                id: itemLinePrefix + uuid.v1(),
                item: {name: ''},
                qty: 0,
                description: '',
                uom: {},
            }
            let ds = this.$refs.dataSource.kendoWidget(),
                total = ds.total();
            ds.insert(total, data)
        },
        ItemDropDownEditor(container, options) {
            kendo.jQuery('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    autoWidth: true,
                    height: 400,
                    filter: "contains",
                    dataTextField: "name",
                    dataValueField: "id",
                    headerTemplate: '<div class="dropdown-header k-widget k-header">' +
                        '<span>Items </span>' +
                        '<span></span>' +
                        '</div>',
                    template: "<span>#=name#</span>",
                    optionLabel: "--- Select ---",
                    dataSource: new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: {
                                url: productVariantHandler.itemSearchURL(),
                            },
                            dataType: "json",
                        },
                        schema: {
                            model: {
                                id: "id",
                                fields: {
                                    id: {type: "string"},
                                    name: {type: "string"},
                                    sku: {type: "string"},
                                }
                            },
                            data: function (response) {
                                return response.data;
                            },
                            total: function (response) {
                                return response.data.count;
                            }
                        },
                        // data: this.variants
                    }),
                })
        },
        UOMDropDownEditor(container, options) {
            kendo.jQuery('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    autoWidth: true,
                    height: 400,
                    filter: "startswith",
                    dataTextField: "uom.name",
                    dataValueField: "uom.id",
                    cascadeFrom: "item",
                    template: "<span>#=uom.name#</span>",
                    optionLabel: "--- Select ---",
                    dataSource: new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: {
                                url: uomPriceHandler.uomPriceByPriceLevelURL('id=' + options.model.item.id + '&plId=' + this.deliveryOrder.priceLevel.id + '&date=' + this.deliveryOrder.date),
                            },
                            dataType: "json",
                        },
                        schema: {
                            model: {
                                id: "id",
                                fields: {
                                    id: {type: "string"},
                                    // name: {type: "string"},
                                    // sku: {type: "string"},
                                }
                            },
                            data: function (response) {
                                return response.data
                            },
                            total: function (response) {
                                return response.data.count
                            }
                        },
                        // data: this.variants
                    }),
                })
        },
        UOMTemplate(dataItem) {
            const uom = dataItem.uom
            if (uom) {
                return `<span>${uom.uom ? uom.uom.name : ``}</span>`
            } else {
                return ``
            }
        },
        removeRow(e) {
            e.preventDefault();
            const grid = kendo.jQuery("#grid").data("kendoGrid"),
                dataSource = grid.dataSource,
                dataItem = grid.dataItem($(e.currentTarget).closest("tr"));

            if (dataSource.total() > 1) {
                dataSource.remove(dataItem);
            }
        },
        rowNumber(dataItem) {
            let ds = this.$refs.dataSource.kendoWidget(),
                index = ds.indexOf(dataItem);
            return index + 1;
        },
        async loadSegment() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    this.segments = []
                    settingsHandler.getSeg().then(res => {
                        if (res.data.statusCode === 200) {
                            this.segments = res.data.data
                            if (this.segments.length > 0) {
                                this.deliveryOrder.segment = this.segments[0]
                            }
                            // this.segmentList = res.data.data
                        }
                    }).catch()
                    {
                        this.showLoading = false
                    }
                }, 300)
            })
        },
        async loadLocation() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    this.locations = []
                    locationHandler.list().then(res => {
                        if (res.data.statusCode === 200) {
                            this.locations = res.data.data
                            if (this.locations.length > 0) {
                                this.deliveryOrder.location = this.locations[0]
                            }
                        }
                    }).catch()
                    {
                        this.showLoading = false
                    }
                }, 300)
            })
        },
        async loadSaleChannel() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    saleChannelHandler.get().then(res => {
                        if (res.data.statusCode === 200) {
                            this.saleChannelList = res.data.data
                            if (this.saleChannelList.length > 0) {
                                this.deliveryOrder.saleChannel = this.saleChannelList[0]
                            }
                        } else {
                            this.showLoading = false
                        }
                    })
                }, 300);
            })
        },
        async loadProjects() {
            await projectHandler.list().then(res => {
                this.projects = res.data.data
                if (this.projects.length > 0) {
                    this.deliveryOrder.project = this.projects[0]
                }
            })
        },
        async onloadWarehouse() {
            warehouseHandler.getWarehouseSettingAll().then(res => {
                this.warehouses = res
                if (this.warehouses.length > 0) {
                    this.deliveryOrder.warehouse = this.warehouses[0]
                }
            })
        },
        async onChange(event) {
            const value = event.value
            if (value && value[textField] === emptyItem[textField]) {
                return;
            }
            this.showLoading = true
            window.console.log(value)
            this.deliveryOrder.customer = value
            this.deliveryOrder.priceLevel = value.priceLevel
            await billingHandler.byCustomer(this.deliveryOrder.customer.id).then(res => {
                window.console.log('customer invoice', res)
                this.invoices = res.data.data
            })
            let data = {
                "method": "by_customer",
                "customer": this.deliveryOrder.customer.id,
                "type": 'Sale Order',
            }
            await saleOrderHandler.search(data).then(res => {
                window.console.log(res)
                this.saleOrders = res.data.data
            })
            this.showLoading = false
            // await this.onloadPurchase()
        },
        onChangeInvoice(e) {
            let selectedIndex = e.sender.select();
            let selectedItem = e.sender.dataItem(selectedIndex).toJSON();
            this.addItemLine(selectedItem)
            if (Object.keys(selectedItem.deliveryAddress).length !== 0 && selectedItem.deliveryAddress === Object) {
                this.deliveryAddress = [selectedItem.deliveryAddress]
                this.deliveryOrder.address = this.deliveryAddress[0]
            }
            window.console.log(selectedItem, 'invoice change')
        },
        // async onloadPurchase() {
        //     this.showLoading = false
        // },
        onFilterChange(event) {
            const filter = event.filter.value
            this.requestData(0, filter, this.customerBaseUrl)
            this.filter = filter
        },
        requestData(skip, filter, baseUrl) {
            const url = baseUrl + `?filter=${filter}`
            this.requestStarted = true;
            fetch(url)
                .then((response) => {
                    // window.console.log(response.json())
                    return response.json()
                })
                .then(this.afterFetch)

        },
        afterFetch(json) {
            this.customerList = json.data
        },
        async loadPrefix() {
            this.showLoading = true
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    prefixHandler.get('delivery order').then(res => {
                        if (res.data.statusCode === 200) {
                            // this.showLoading = false
                            this.transactionTypes = res.data.data
                            if (this.transactionTypes.length > 0) {
                                this.deliveryOrder.transactionType = this.transactionTypes[0]
                                if (this.$route.params.id === undefined) {
                                    this.generateNumber()
                                }
                            }
                        }

                    })
                }, 300);
            })
            await this.onloadWarehouse()
            await this.loadSaleChannel()
            // await this.loadLocation()
            // await this.loadProjects()
            // await this.loadSegment()
            await this.onloadDeliveryAgency()
            this.showLoading = false
        },
        generateNumber() {
            let data = {
                abbr: this.deliveryOrder.transactionType.abbr,
                structure: this.deliveryOrder.transactionType.structure,
                transactionDate: this.deliveryOrder.date,
                sequcencing: this.deliveryOrder.transactionType.sequcencing,
                type: 'Delivery Order',
                entity: 3,
            }
            this.deliveryOrder.abbr = data.abbr
            this.deliveryOrder.structure = data.structure
            this.deliveryOrder.sequcencing = data.sequcencing
            this.deliveryOrder.type = data.type
            this.deliveryOrder.transactionDate = data.transactionDate
            billingHandler.lastNumber(data).then(response => {
                if (response.data.statusCode === 200) {
                    const res = response.data.data
                    const lastNumber = this.zeroPad(parseInt(res.lastNumber), this.deliveryOrder.transactionType.format)
                    this.deliveryOrder.number = res.suffix + this.deliveryOrder.transactionType.numberSeparator + lastNumber
                    this.deliveryOrder.lastNumber = res.suffix + lastNumber
                    window.console.log(this.deliveryOrder.number, 'bom')
                }
            }).catch(e => {
                this.errors.push(e)
            })
        },
        zeroPad(num, places) {
            return String(num).padStart(places, '0')
        },
        suffix(transactionDate) {
            return kendo.toString(new Date(transactionDate), `yymm`)
        },
        savePrint() {

        },
        async getDataSource() {
            let ds = this.$refs.dataSource.kendoWidget()
            let dataRow = ds.data()
            this.itemLines = []
            dataRow.forEach(i => {
                if (i.invoiceId === undefined && i.type === undefined) {
                    i.invoiceId = ''
                    i.type = ''
                }
                this.itemLines.push({
                    id: i.id,
                    item: i.item,
                    qty: i.qty,
                    uom: i.uom,
                    description: i.description,
                    invoiceId: i.invoiceId,
                    type: i.type
                })
            })
            window.console.log(this.itemLines)
        },
        async saveNew() {
            this.showLoading = true
            await this.getDataSource()
            this.deliveryOrder.itemLines = this.itemLines
            deliveryOrderHandler.save(this.deliveryOrder).then(res => {
                window.console.log(res)
                this.showLoading = false
            })
        },
        saveClose() {

        },
        errorMessage() {

        },
        accountDropDownEditor() {
        },
        cancel() {

        },
        hide_smallsitebar() {
            if (!this.isHideBar) {
                // this.col_expand = 12
                // this.col_hide = 0
                this.isHideBar = true
            } else {
                // this.col_expand = 9
                // this.col_hide = 3
                this.isHideBar = false
            }
        }
    },
    watch: {
        // call again the method if the route changes

    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        // this.loadObj()
    },
    async mounted() {
        this.addRow()
        this.requestData(0, '', customerHandler.search())
        await this.loadPrefix()
        // await customerHandler.center().then(res => {
        //     window.console.log(res, 'customer center')
        // })
    },
};
</script>

<style scoped>
.k-dropdown {
    width: 100%;
    margin-top: 3px;
}

.function_wrapper {
    box-shadow: none !important;
}

.v-application--is-ltr .v-text-field .v-input__append-inner {
    margin-top: 0 !important;
}

.v-input__slot {
    background-color: #fff !important;
}

.function_content .label {
    margin-bottom: 10px;
    display: inline-block;
}

.border_radius10 {
    border-radius: 10px !important;
    background-color: #F2F2F2;
}

.pa-3.v-card h4 {
    font-size: 18px;
    color: #333;
}

.pa-3.v-card p {
    font-size: 12px;
    color: #B5B5B5;
}

.custom_grid table th:last-child {
    text-align: right !important;
}

.color_green {
    color: #03b154;
}

@media (min-width: 1264px) {
    .container {
        max-width: 1250px;
    }
}

@media (max-width: 576px) {
    .pt-6.col-sm-5.col-12 {
        padding-top: 0 !important;
    }

    .code_text {
        width: 100%;
    }

    .phone_no_pt {
        padding-top: 0 !important;
    }

    .select_template,
    .save_option {
        margin-bottom: 10px;
    }
}

.hide_small_bar_class {
    max-width: 0;
    transition: .5s ease-in;
    flex: 0 0 0;
}

.hide_big_bar_class {
    max-width: 100%;
    transition: .5s ease-in;
    flex: 0 0 100%;
}

.info_add {
    background-color: #ffffff;
}

.small_sidebar {
    height: 96%;
    position: relative;
    padding: 12px 12px 0 12px;
    background-color: #EDF1F5;
}

.iconArrow {
    right: -35px;
    position: absolute;
    bottom: -10px;
}

.iconArrowHide {
    position: absolute;
    right: -7px;
    bottom: -10px;
}

.color_grey {
    color: #808080;
}

.card_green {
    min-height: 70px;
    background-color: #00b050 !important;
    color: #ffffff;
}

.lb_bold {
    font-size: 12px;
}

.card_background {
    background-color: #EDF1F5;
    min-height: 120px;
}

.deposite_input {
    width: 100px;
}

.btn_save_draft {
    color: #ffffff;
    background-color: #00b0f0 !important;
    text-transform: capitalize;
}

.save_option {
    background-color: #203864 !important;
}

.btn_add_small {
    height: 27px !important;
    min-width: 25px !important;
    font-size: 10px;
    padding: 0 22px !important;
    background-color: #00b050 !important;
    color: #ffffff;
    border-radius: 0 !important;
}

.list_site_inv {
    background-color: #92d050;
    color: #ffffff;
    font-size: 12px;
}

.list_site_exp {
    background-color: #c5e0b4;
    color: #000000;
    font-size: 12px;
    line-height: 16px;
    min-height: 40px;
}

.checkbox_inv {
    padding: 2px;
    margin-top: 3px;
    margin-right: 2px;
}

.exp_select {
    font-size: 12px !important;
}

.v-data-table__wrapper td {
    border-bottom: 1px solid #808080;
    border-top: 1px solid #808080;
}

</style>
